import React, { useState, useEffect } from 'react';
import './App.css';

import nutrientData from './nutrientData.json';
import oilsData from './oilsData.json';
import giglData from './giglData.json';

function getGIColor(value) {
  const gi = parseInt(value);
  if (gi >= 70) return 'high-gi';
  if (gi >= 56) return 'medium-gi';
  return 'low-gi';
}

function getGLColor(value) {
  const gl = parseInt(value);
  if (gl >= 20) return 'high-gl';
  if (gl >= 11) return 'medium-gl';
  return 'low-gl';
}

function toTitleCase(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) { return str.toUpperCase(); });
}

function TableOrCards({ data, headers, filterTerm, isMobile, activeTable }) {
  const filteredData = data.filter(row =>
    Object.values(row).some(value =>
      value.toString().toLowerCase().includes(filterTerm.toLowerCase())
    )
  );

  const renderGIGLValue = (value, type) => (
    <span className={`gi-gl-value ${type === 'glycemicIndex' ? getGIColor(value) : getGLColor(value)}`}>
      {value}
    </span>
  );

  const renderCellContent = (header, value, isGIGL) => {
    if (header === 'foodSources') {
      return (
        <ul className="food-sources-list">
          {value.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    } else if (isGIGL && (header === 'glycemicIndex' || header === 'glycemicLoad')) {
      return renderGIGLValue(value, header);
    } else {
      return value;
    }
  };

  /* 
  Commenting this as this was not having row title for gigl on mobile view. Will fix this later if needed.
  For the time being the mobile view for gigl will be same as desktop view which is enabled by adding `activeTable !== 'gigl'` in `  if (isMobile && activeTable !== 'gigl') {`
  The related CSS for this has also been commented.
  */
  // if (isMobile && activeTable === 'gigl') {
  //   return (
  //     <div className="gigl-container">
  //       {filteredData.map((row, rowIndex) => (
  //         <div key={rowIndex} className="gigl-row">
  //           <span className="food-item">{row[headers[0]]}</span>
  //           <div className="gi-gl-values">
  //           {renderGIGLValue(row.glycemicLoad, 'glycemicLoad')}
  //           {renderGIGLValue(row.glycemicIndex, 'glycemicIndex')}
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  if (isMobile && activeTable !== 'gigl') {
    return (
      <div className="cards-container">
        {filteredData.map((row, rowIndex) => (
          <div key={rowIndex} className="card">
            <h2 className="card-heading">{row[headers[0]]}</h2>
            {headers.slice(1).map((header, cellIndex) => (
              <div key={cellIndex} className="card-item">
                <strong>{toTitleCase(header)}:</strong>
                {renderCellContent(header, row[header], activeTable === 'gigl')}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => <th key={index}>{toTitleCase(header)}</th>)}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, cellIndex) => (
                <td key={cellIndex}>
                  {renderCellContent(header, row[header], activeTable === 'gigl')}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function About({ content }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="about-section">
      <button onClick={() => setIsExpanded(!isExpanded)} className="about-toggle">
        {isExpanded ? 'Hide' : 'Learn more'}
      </button>
      {isExpanded && (
        <div className="about-content">
          <p>{content.introduction}</p>
          <h3>Field Descriptions:</h3>
          <ul>
            {Object.entries(content.fields).map(([field, description]) => (
              <li key={field}>
                <strong>{field}:</strong> {description}
              </li>
            ))}
          </ul>
          <p><strong>Note:</strong> {content.note}</p>
        </div>
      )}
    </div>
  );
}

function Legend() {
  return (
    <div className="legend">
      <h3>Color Legend:</h3>
      <div className="legend-item">
        <span className="legend-color low-gi"></span> Low
      </div>
      <div className="legend-item">
        <span className="legend-color medium-gi"></span> Medium
      </div>
      <div className="legend-item">
        <span className="legend-color high-gi"></span> High
      </div>
      <p>GI: Low (1-55), Medium (56-69), High (70+)</p>
      <p>GL: Low (1-10), Medium (11-19), High (20+)</p>
    </div>
  );
}

function App() {
  const [activeTable, setActiveTable] = useState('nutrients');
  const [filterTerm, setFilterTerm] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tableData = {
    nutrients: {
      data: nutrientData.nutrients,
      headers: ['nutrient', 'benefits', 'deficiencyProblems', 'dailyRequirement', 'foodSources'],
      notes: nutrientData.notes,
      sources: nutrientData.sources,
      about: nutrientData.about
    },
    oils: {
      data: oilsData.oils,
      headers: ['name', 'saturatedFat', 'unsaturatedFat', 'transFat', 'cholesterol', 'smokePoint', 'antioxidantContent', 'energy', 'recommendedCookingMethods'],
      notes: [],
      sources: [],
      about: oilsData.about
    },
    gigl: {
      data: giglData.foods,
      headers: ['foodItem', 'glycemicLoad', 'glycemicIndex'],
      notes: giglData.notes,
      sources: giglData.sources,
      about: giglData.about
    }
  };

  return (
    <div className="App">
      <h1>Nutrition Information</h1>
      <div className={`table-selector ${isMobile ? 'mobile' : ''}`}>
        <button 
          onClick={() => setActiveTable('nutrients')}
          className={activeTable === 'nutrients' ? 'active' : ''}
        >
          {isMobile ? (
            <>
              <span>N</span>
              <small>Nutrients</small>
            </>
          ) : (
            'Nutrients'
          )}
        </button>
        <button 
          onClick={() => setActiveTable('oils')}
          className={activeTable === 'oils' ? 'active' : ''}
        >
          {isMobile ? (
            <>
              <span>O</span>
              <small>Oils</small>
            </>
          ) : (
            'Oils'
          )}
        </button>
        <button 
          onClick={() => setActiveTable('gigl')}
          className={activeTable === 'gigl' ? 'active' : ''}
        >
          {isMobile ? (
            <>
              <span>G</span>
              <small>GI/GL</small>
            </>
          ) : (
            'GI/GL'
          )}
        </button>
      </div>
      <About content={tableData[activeTable].about} />
      <div className="filter-bar">
        <input
          type="text"
          placeholder="Filter..."
          value={filterTerm}
          onChange={(e) => setFilterTerm(e.target.value)}
        />
        {filterTerm && (
          <button className="clear-filter" onClick={() => setFilterTerm('')}>
            ×
          </button>
        )}
      </div>
      <TableOrCards
        data={tableData[activeTable].data}
        headers={tableData[activeTable].headers}
        filterTerm={filterTerm}
        isMobile={isMobile}
        activeTable={activeTable}
      />
      {activeTable === 'gigl' && <Legend />}
      {tableData[activeTable].notes && tableData[activeTable].notes.length > 0 && (
        <div className="notes">
          <h2>Notes:</h2>
          {tableData[activeTable].notes.map((note, index) => (
            <p key={index}>{note}</p>
          ))}
        </div>
      )}
      {tableData[activeTable].sources && tableData[activeTable].sources.length > 0 && (
        <div className="sources">
          <h2>Sources:</h2>
          <ul>
            {tableData[activeTable].sources.map((source, index) => (
              <li key={index}>{source}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;